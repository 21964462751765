import { createContext, useContext, useEffect, useState, useMemo } from 'react';
import { useHistory } from 'react-router-dom';
import {
  testServerStatusRequest,
  fetchRolesRequest,
  logoutUserRequest,
} from 'http/requests';
import {
  addToken,
  clearToken,
  isTokenValid,
  jwtDecode,
  clearRowperPage
} from 'utils/jsonwebtoken';
import ServerDown from 'pages/ServerDown/ServerDown';
import { IF } from 'utils/IF';
import { SnackbarUtils } from 'ui';
import _ from 'lodash';
import io from 'socket.io-client';

const Context = createContext({
  serverUp: true,
  isLoggedin: true,
  isLoading: true,
  role: '',
  roles: [],
  socket: '',
  login: () => {},
  loginAsAnotherUser: () => {},
});

export const useAuthContext = () => {
  return useContext(Context);
};

const Provider = ({ children }) => {
  const [serverUp, setServerUp] = useState(true);
  const [isLoggedin, setIsLoggedin] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [user, setUser] = useState({});
  const [roles, setRoles] = useState([]);

  const history = useHistory();

  useEffect(() => {
    setIsLoading(true);
    (async () => {
      try {
        await testServerStatusRequest();
        setServerUp(true);

        SnackbarUtils.success('Servern är igång');
      } catch (error) {
        setServerUp(false);

        SnackbarUtils.error('servern är nere');
      } finally {
        setIsLoading(false);
      }
    })();
  }, []);

  useEffect(() => {
    if (!isLoggedin) return;
    (async () => {
      try {
        const response = await fetchRolesRequest();
        setRoles(response.data.roles);
      } catch (error) {
        SnackbarUtils.error(error?.response?.data?.message);
      }
    })();
  }, [isLoggedin]);

  useEffect(() => {
    if (isTokenValid()) {
      setIsLoggedin(true);
      setUser(jwtDecode());
      const pathName = window.location.pathname;
      if (pathName === '/login') {
        history.push('/');
      }
    } else {
      setIsLoggedin(false);
      const linkArray = window.location.href.split('/');
      if (linkArray.includes('reset-password')) {
        history.push(`/reset-password/${linkArray[4]}/${linkArray[5]}`);
      } else {
        history.push('/login');
      }
    }
  }, [history]);
  const socketURL = process.env.REACT_APP_SOCKET_SERVER_URL || '';
  let socket;
  // = useMemo(() => {
  //   if (isLoggedin) {
  //     return io(socketURL, {
  //       withCredentials: true,
  //       secure: true,
  //     });
  //   }
  // }, [isLoggedin]);

  const login = (accessToken) => {
    SnackbarUtils.success('Inloggningen lyckades');
    addToken(accessToken);
    setIsLoggedin(true);

    if (isTokenValid()) {
      let decodedUserInfo = jwtDecode();
      let role = roles?.find((role) => role.id === decodedUserInfo?.role)?.name;
      if (role == 'SUPER_ADMIN' || role == 'RESELLER') {
        history.push('/companies');
      } else {
        history.push('/');
      }
      if (!decodedUserInfo?.role) {
        SnackbarUtils.error('Något gick fel');
        logout();
      } else {
        setIsLoggedin(true);
        setUser(jwtDecode());
      }
    }
  };
  const logoutUser = async () => {
    try {
      await logoutUserRequest();
    } catch (error) {}
  };
  const logout = () => {
    logoutUser();
    clearToken();
    clearRowperPage();
    setIsLoggedin(false);

    history.push('/login');
    //Signed out replace with Utloggad
    SnackbarUtils.success('Utloggad');
  };

  const role = roles?.find((role) => role.id === user.role)?.name;

  const value = {
    serverUp,
    isLoggedin,
    isLoading,
    user,
    roles,
    role,
    socket,
    login,
    logout,
  };

  return (
    <Context.Provider value={value}>
      <IF condition={serverUp}>{children}</IF>
      <IF condition={!serverUp}>
        <ServerDown />
      </IF>
    </Context.Provider>
  );
};

export default Provider;
